import React from 'react';

const NotFoundPage = () => {
    return (
        <div className="px-80 pt-10">
            <img src="https://cdn2.wpbeginner.com/wp-content/uploads/2013/04/wp404error.jpg" alt="Shoes" />
        </div>
    );
};

export default NotFoundPage;