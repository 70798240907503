import { GoogleAuthProvider } from 'firebase/auth';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthProvider';
import useToken from '../../hooks/useToken';
// import Navbar from '../Shared/Navbar/Navbar';

const Login = () => {
    const { register, formState: { errors }, handleSubmit } = useForm();
    const { signIn, providerLogin } = useContext(AuthContext);
    const [loginError, setLoginError] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || '/';
    const [loginUserEmail, setLoginUserEmail] = useState('');
    const [token] = useToken(loginUserEmail);
    // const [currentValue, setCurrentValue] = useState('buyer');
    if (token) {
        navigate(from, { replace: true });
    }

    //google signup
    const googleProvider = new GoogleAuthProvider();
    const handleGoogleSignIn = () => {
        providerLogin(googleProvider)
            .then(res => {
                const user = res.user;
                // console.log(user);

                navigate('/');

            })
            .catch(err => console.error(err));

    }


    // const changeUser = (selectUser) => {
    //     //  console.log(selectUser)
    //     setCurrentValue(selectUser)
    // }


    const handleLogin = data => {


        setLoginError('');
        signIn(data.email, data.password)
            .then(result => {
                const user = result.user;
                //console.log(user);
                setLoginUserEmail(data.email);
                navigate(from, { replace: true });
                //    setLoginUserEmail(data.email);
            })
            .catch(error => {
                console.log(error.message)
                setLoginError(error.message);
            });
        // console.log(currentValue)
    }

    return (
        <div>
            <div className='h-[800px] flex justify-center items-center'>
                <div className='w-96 p-7'>
                    <h2 className='text-xl text-center py-7'>Login</h2>
                    <form onSubmit={handleSubmit(handleLogin)}>
                        <div
                            className='flex gap-6 px-20'

                        >

                            {/* <label htmlFor="">
                                <input type="radio" name="radio-2"
                                    value="seller"
                                    className="radio radio-primary"
                                    checked={currentValue === 'seller'}
                                    {...register("role", {
                                        required: "User Role is Required"
                                    })}
                                    onChange={(event) => changeUser(event.target.value)}

                                />
                                Seller</label> */}

                            {/* <label htmlFor="">
                                <input type="radio" name="radio-2"
                                    value="buyer"
                                    className="radio radio-primary"
                                    checked={currentValue === 'buyer'}
                                    {...register("role", {
                                        required: "User Role is Required"
                                    })}
                                    onChange={(event) => changeUser(event.target.value)}
                                />
                                Buyer </label> */}
                        </div>

                        <div className="form-control w-full max-w-xs">
                            <label className="label"> <span className="label-text">Email</span></label>
                            <input type="text"
                                {...register("email", {
                                    required: "Email Address is required"
                                })}
                                className="input input-bordered w-full max-w-xs" />
                            {errors.email && <p className='text-red-600'>{errors.email?.message}</p>}
                        </div>
                        <div className="form-control w-full max-w-xs py-7">
                            <label className="label"> <span className="label-text">Password</span></label>
                            <input type="password"
                                {...register("password", {
                                    required: "Password is required",
                                    minLength: { value: 6, message: 'Password must be 6 characters or longer' }
                                })}
                                className="input input-bordered w-full max-w-xs " />
                            {/* <label className="label"> <span className="label-text">Forget Password?</span></label> */}
                            {errors.password && <p className='text-red-600'>{errors.password?.message}</p>}
                        </div>
                        <input className='btn btn-accent w-full' value="Login" type="submit" />
                        <div>
                            {loginError && <p className='text-red-600'>{loginError}</p>}
                        </div>
                    </form>
                    <p>New to Recycled Books <Link className='text-primary' to="/signup">Create new Account</Link></p>
                    <div className="divider">OR</div>
                    <button
                        className='btn btn-outline w-full'
                        onClick={handleGoogleSignIn}
                    >CONTINUE WITH GOOGLE</button>
                </div>
            </div>

        </div>
    );
};

export default Login;