import { RouterProvider } from 'react-router-dom';
import router from './Routes/Routes/Routes';

import './App.css';
import { Toaster } from 'react-hot-toast';

function App() {
  return (
    <div className='mx-auto'>
      <RouterProvider router={router}></RouterProvider>

      <Toaster></Toaster>


    </div>
  );
}

export default App;
